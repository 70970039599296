let focusTrapped = false;
let $opener = null;
const ariaLabelFallback = 'View disclaimer, opens a dialog';

$(document).ready(function() {
   var disContent = '';

   $('.disclaimer-callout:not([href])').attr('href', '#');
   $('.disclaimer-callout:not([role])').attr('role', 'button');
   $('.disclaimer-callout:not([aria-label])').attr('aria-label', ariaLabelFallback);

       // open disc
     // add .active class for animation
     $(document.body).on('click', '.disclaimer-callout', function(e) {
       e.preventDefault();
       $opener = $(this);
       callDisclaimer($(this));
     }); //click END

     // close disc
     $('.disclaimers-close').on('click', function(e) {
       $('.disclaimers').removeClass('active');
       $opener.focus();
     }); //click END

     // On scroll
     // hide disc
     $(window).scroll(function() {
       //check id disc is active
       if ($('.disclaimers').hasClass('active')) {
         $('.disclaimers').removeClass('active');
       }

     });


 }); //document ready

    /**
     * Callback for when the disclaimer dialog is finished opening or closing
     */
    // const onTransitionEnd = () => {
    //   const $dialog = $('.disclaimers[role="dialog"]');
    //   if ($dialog.hasClass('active')) {
    //     $dialog
    //       .attr('tabindex', '0')
    //       .focus();
    //     focusTrapped = true;
    //   } else {
    //     if ($opener != null) { 
    //       $opener.focus();
    //       $opener = null;
    //     }
    //     $dialog.removeAttr('tabindex');
    //     focusTrapped = false;
    //   }
    // };

    // General cleanup on window unload
    window.addEventListener('unload', () => {
      $(document).off('keydown');
      $(document.body).off('click');
      $('.disclaimers[role="dialog"]').on('transitionend');
      $('.disclaimer-callout').off('click');
      $(window).off('scroll');
      $opener = null;
    }, { once: true, passive: true });

    // Function to trap focus within the modal
    function trapFocus() {
      const modal = $('.disclaimers.active');
      const focusableElements = modal.find(
        'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
      );
      const firstElement = focusableElements.first();
      const lastElement = focusableElements.last();
  
      modal.on('keydown', function(event) {
        if (event.key === 'Tab') {
          if (event.shiftKey) {
            if (document.activeElement === firstElement[0]) {
              event.preventDefault();
              lastElement.focus();
            }
          } else {
            if (document.activeElement === lastElement[0]) {
              event.preventDefault();
              firstElement.focus();
            }
          }
        }
      });
    }

 function callDisclaimer(obj) {
    const modal = $('.disclaimers[role="dialog"]');
   const disContent = obj.attr('data-disclaimers-content');
   //console.log('disclaimersContent: ', disContent);
   $('.disclaimers').addClass('active');
   $('.disclaimers-content p').html(disContent);
   modal.attr('tabindex', '0');
   modal.focus();
   trapFocus();
 }